<template>
  <div class="mt-3">
    <div
      v-if="show_id == 'casino' || show_id == 'showtv'"
      class="home_game_arrow home_game_casino g-flex-wrap"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3" v-if="show_id == 'casino'">
          <svg
            id="menu_icon_livecasino"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="40.116"
            height="40.068"
            viewBox="0 0 40.116 40.068"
            class="svg_w"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_586"
                  data-name="Rectangle 586"
                  width="40.116"
                  height="40.068"
                  fill="#FFF"
                ></rect>
              </clipPath>
            </defs>
            <g id="Group_20" data-name="Group 20" transform="translate(0 0)">
              <path
                id="Path_24"
                data-name="Path 24"
                d="M3.209,0A3.208,3.208,0,0,0,0,3.209V35.3a3.208,3.208,0,0,0,3.209,3.209H25.674A3.208,3.208,0,0,0,28.883,35.3V3.209A3.208,3.208,0,0,0,25.674,0ZM31.7,1.088a6.335,6.335,0,0,1,.388,2.121V35.3a6.4,6.4,0,0,1-1.884,4.538l1.187.188a3.213,3.213,0,0,0,3.673-2.67l5.006-31.7a3.209,3.209,0,0,0-2.667-3.673ZM14.442,8.023a1.565,1.565,0,0,1,.878.3l.075.018c2.848,2.319,8.675,8.065,8.675,11.722a5.616,5.616,0,0,1-5.616,5.616,5.551,5.551,0,0,1-3.419-1.194,8.142,8.142,0,0,0,3.721,4.459v0a.8.8,0,0,1-.3,1.545H10.43a.8.8,0,0,1-.3-1.545v0A8.136,8.136,0,0,0,13.85,24.48a5.551,5.551,0,0,1-3.419,1.194,5.616,5.616,0,0,1-5.616-5.616c0-3.657,5.826-9.4,8.675-11.722l.075-.018a1.565,1.565,0,0,1,.878-.3"
                transform="translate(0 0)"
                fill="#FFF"
              ></path>
            </g>
          </svg>
          <span class="text_span"> {{ $t("home_text6").toUpperCase() }}</span>
        </div>

        <div class="game_logo_img fw-bolder my-3" v-else>
          <img
            src="@/assets/img/game_category/menu_show.png"
            alt=""
            style="height: 30px; widows: 30px"
          />
          <span class="text_span"> {{ $t("text_main13").toUpperCase() }}</span>
        </div>

        <!-- 
        <div class="top_arrow">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('slot_' + j, 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('slot_' + j, 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div> -->
      </div>

      <div v-if="show_id == 'casino'">
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            v-for="l in c_s_list('casino')"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-else>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            v-for="l in c_s_list('showtv')"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="home_game_arrow home_game_sport" v-if="show_id == 'sport'">
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6929 21.9119C13.1353 22.0079 12.5662 22.0684 11.9818 22.0684C6.43282 22.0684 1.91651 17.5507 1.91651 12C1.91651 6.44935 6.43282 1.93165 11.9818 1.93165C17.5307 1.93165 22.047 6.44935 22.047 12C22.047 12.2957 22.0278 12.5866 22.0029 12.8755C22.6583 13.1894 23.2562 13.6061 23.7658 14.1129C23.8887 13.4256 23.9635 12.7219 23.9635 12C23.9635 5.3924 18.5873 0.0145493 11.9818 0.0145493C5.3762 0.0145493 0 5.3924 0 12C0 18.6076 5.3762 23.9855 11.9818 23.9855C13.0537 23.9855 14.0873 23.8309 15.0758 23.565C14.5269 23.0975 14.0595 22.5378 13.6929 21.9119Z"
              fill="#F9F8F8"
            ></path>
            <path
              d="M5.0672 16.7529H8.52978L9.59887 20.0437C10.3551 20.2655 11.1536 20.3913 11.9818 20.3913C12.3378 20.3913 12.6862 20.3577 13.0288 20.3135C12.8906 19.7884 12.809 19.2402 12.809 18.671C12.809 17.4566 13.1526 16.3257 13.7371 15.3571H9.52882L8.01154 10.6896L11.9818 7.80485L15.952 10.6896L14.8935 13.9421C16.0384 12.8909 17.5595 12.2429 19.2351 12.2429C19.6142 12.2429 19.9837 12.2822 20.3446 12.3456C20.3465 12.3014 20.357 12.2592 20.358 12.215L17.5662 10.1866L18.6344 6.89575C17.6574 5.62088 16.3225 4.63977 14.7774 4.09546L11.9818 6.12679L9.18716 4.09546C7.64014 4.63977 6.30617 5.62088 5.32824 6.89575L6.39926 10.1847L3.60559 12.215C3.64782 13.8989 4.17661 15.4579 5.0672 16.7529Z"
              fill="#F9F8F8"
            ></path>
            <path
              d="M19.2352 13.9037C16.6037 13.9037 14.4703 16.0377 14.4703 18.67C14.4703 21.3033 16.6037 23.4363 19.2352 23.4363C21.8666 23.4363 24 21.3033 24 18.67C24 16.0377 21.8666 13.9037 19.2352 13.9037ZM21.0442 18.8313L21.0403 18.839L18.0231 20.8233C17.9943 20.8415 17.9597 20.853 17.9252 20.8549C17.8935 20.8549 17.8628 20.8463 17.835 20.8309C17.7745 20.7954 17.738 20.7292 17.7371 20.6562V16.6886C17.738 16.6166 17.7745 16.5494 17.835 16.5139C17.8945 16.4764 17.9684 16.4764 18.0269 16.5139L21.0442 18.4982C21.0998 18.5337 21.1334 18.5961 21.1334 18.6642C21.1334 18.7334 21.0998 18.7958 21.0442 18.8313Z"
              fill="#F9F8F8"
            ></path>
          </svg>
          <span class="text_span"> {{ $t("text_sport").toUpperCase() }}</span>
        </div>
        <span
          class="see-all-btn text_span"
          @click="this.$router.push('/main_sport')"
          >{{ $t("text_seeall") }}</span
        >
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide
          v-for="l in c_s_list('sport')"
          :key="l"
          class="home_game_img"
        >
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
              class="game_item"
            >
              <img v-lazy="l.iconName" class="w-100" />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="show_id == 'slot'">
      <div
        class="home_game_arrow home_game_slot_sub"
        :class="[
          'home_game_slot_' + j,
          sub_type != null && sub_type != '' ? 'g-flex-wrap' : '',
        ]"
        v-for="j in filterSearch"
        :key="j"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <img
              :src="
                j.toUpperCase().indexOf('A1-') != -1
                  ? require('@/assets/img/allinslot/logo/slot_a1_' +
                      j.split('-')[1].toLowerCase() +
                      '.png')
                  : require('@/assets/img/game/' + j + '.png')
              "
              :title="j.toUpperCase()"
              style="width: 55px; height: 25px"
            />&nbsp;{{
              j.toUpperCase().indexOf("A1-") != -1
                ? j.split("-")[1].toUpperCase()
                : j.toUpperCase()
            }}
          </div>
          <div class="top_arrow" v-if="sub_type == null || sub_type == ''">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('slot_' + j, 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('slot_' + j, 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide v-for="l in pp_list(j)" :key="l" class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="w-25 mx-auto text-center w-lg-75" v-if="flag_slot == 0">
        <button class="w-100 btn btn-success main_color" @click="more()">
          <a>{{ $t("slot_text2") }}</a>
        </button>
      </div>
    </div>
    <div
      v-if="show_id == 'fish'"
      class="home_game_arrow home_game_casino g-flex-wrap"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/menu_fish.png"
            alt=""
            style="height: 30px; width: 30px"
          />
          <span class="text_span"> {{ $t("text_main12").toUpperCase() }}</span>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', 'slot', '42', 'JILI', 'slot')"
              class="game_item"
            >
              <img
                src="http://gameweb.xkzkji.com/images/jili/GameID_42_EN.png"
                class="w-100"
              />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', 'slot', '20', 'JILI', 'slot')"
              class="game_item"
            >
              <img
                src="http://gameweb.xkzkji.com/images/jili/GameID_20_EN.png"
                class="w-100"
              />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', 'slot', '30', 'JILI', 'slot')"
              class="game_item"
            >
              <img
                src="http://gameweb.xkzkji.com/images/jili/GameID_30_EN.png"
                class="w-100"
              />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', 'slot', '1', 'JILI', 'slot')"
              class="game_item"
            >
              <img
                src="http://gameweb.xkzkji.com/images/jili/GameID_1_EN.png"
                class="w-100"
              />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="home_game_img">
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game('', 'slot', '32', 'JILI', 'slot')"
              class="game_item"
            >
              <img
                src="http://gameweb.xkzkji.com/images/jili/GameID_32_EN.png"
                class="w-100"
              />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="show_id == 'providers'">
      <div class="home_game_arrow home_game_slot home_game_allcasino">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <svg
              id="menu_icon_livecasino"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="40.116"
              height="40.068"
              viewBox="0 0 40.116 40.068"
              class="svg_w"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_586"
                    data-name="Rectangle 586"
                    width="40.116"
                    height="40.068"
                    fill="#FFF"
                  ></rect>
                </clipPath>
              </defs>
              <g id="Group_20" data-name="Group 20" transform="translate(0 0)">
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M3.209,0A3.208,3.208,0,0,0,0,3.209V35.3a3.208,3.208,0,0,0,3.209,3.209H25.674A3.208,3.208,0,0,0,28.883,35.3V3.209A3.208,3.208,0,0,0,25.674,0ZM31.7,1.088a6.335,6.335,0,0,1,.388,2.121V35.3a6.4,6.4,0,0,1-1.884,4.538l1.187.188a3.213,3.213,0,0,0,3.673-2.67l5.006-31.7a3.209,3.209,0,0,0-2.667-3.673ZM14.442,8.023a1.565,1.565,0,0,1,.878.3l.075.018c2.848,2.319,8.675,8.065,8.675,11.722a5.616,5.616,0,0,1-5.616,5.616,5.551,5.551,0,0,1-3.419-1.194,8.142,8.142,0,0,0,3.721,4.459v0a.8.8,0,0,1-.3,1.545H10.43a.8.8,0,0,1-.3-1.545v0A8.136,8.136,0,0,0,13.85,24.48a5.551,5.551,0,0,1-3.419,1.194,5.616,5.616,0,0,1-5.616-5.616c0-3.657,5.826-9.4,8.675-11.722l.075-.018a1.565,1.565,0,0,1,.878-.3"
                  transform="translate(0 0)"
                  fill="#FFF"
                ></path>
              </g>
            </svg>
            <span class="text_span"> {{ $t("home_text6").toUpperCase() }}</span>
          </div>

          <div class="top_arrow">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('allcasino', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('allcasino', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            v-for="l in c_s_list('allcasino')"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="home_game_arrow home_game_slot home_game_allslot">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 42 37.134"
            >
              <defs>
                <clipPath id="clipPath">
                  <rect
                    id="Rectangle_585"
                    data-name="Rectangle 585"
                    width="42"
                    height="37.134"
                    fill="#FFF"
                  ></rect>
                </clipPath>
              </defs>
              <g id="menu_icon_slots" transform="translate(0 0)">
                <g
                  id="Group_18"
                  data-name="Group 18"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M16.169,0A22.1,22.1,0,0,0,8.178,1.614H4.867A3.228,3.228,0,0,0,1.638,4.843V33.9a1.615,1.615,0,1,0-.045,3.229H30.7a1.615,1.615,0,0,0,.045-3.229H30.7V4.843a3.228,3.228,0,0,0-3.23-3.229H24.158A22.094,22.094,0,0,0,16.169,0m22.6,4.843a3.227,3.227,0,0,0-1.614,6.023v8.508a1.589,1.589,0,0,1-1.614,1.614H33.928v3.229h1.614a4.868,4.868,0,0,0,4.843-4.843V10.863a3.225,3.225,0,0,0-1.614-6.019M8.1,6.457H24.241a3.228,3.228,0,0,1,3.229,3.229v6.459a3.228,3.228,0,0,1-3.229,3.229H8.1a3.228,3.228,0,0,1-3.229-3.229V9.686A3.228,3.228,0,0,1,8.1,6.457m0,9.688h3.229V9.688H8.1Zm6.459,0h3.229V9.688H14.554Zm6.457,0h3.229V9.688H21.012ZM8.1,24.217H24.241v6.457H8.1Z"
                    transform="translate(0 -0.001)"
                    fill="#FFF"
                  ></path>
                </g>
              </g>
            </svg>
            <span class="text_span"> {{ $t("home_text7").toUpperCase() }}</span>
          </div>

          <div class="top_arrow">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('allslot', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('allslot', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide v-for="l in slot_list" :key="l" class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="set_gametype(l.toUpperCase())"
                class="game_item"
              >
                <img
                  :src="
                    l.indexOf('A1-') != -1
                      ? require('@/assets/img/allinslot/' +
                          l.split('-')[1] +
                          '.png')
                      : require('@/assets/img/game/all/slot_' + l + '.png')
                  "
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      v-if="show_id == 'hot'"
      class="home_game_arrow home_game_casino g-flex-wrap"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game/hot.png"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span class="text_span"> {{ $t("text_main11") }}</span>
        </div>
      </div>

      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide
          v-for="l in c_s_list('hot')"
          :key="l"
          class="home_game_img"
        >
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
              class="game_item"
            >
              <img v-lazy="l.iconName" class="w-100" />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { mapActions, mapGetters } from "vuex";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
export default {
  props: { show_id: String, allinslot: Array },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      class: "flex-wrap",
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      css_list: "position-relative",
      flag_slot: 0,
    };
  },
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      var action_val = t != "allcasino" && t != "sport" ? "GameLobby" : "";

      var gametype =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[0]
          : t;
      var type =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[1]
          : "all";

      let param = {
        action: action_val,
        body: {
          gametype: gametype,
          type: type,
          uid: localStorage.getItem("uid"),
        },
      };
      this.getGameList(param);
    },
    set_gametype: function (e) {
      this.$router.push("/slot/slot/" + e);
    },
    game: function (device, mode, id, type, kind) {
      this.$parent.game(device, mode, id, type, kind);
    },
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    change_page(val) {
      if (val == "slot") {
        this.flag_slot = 0;
        this.all_game["slot"].forEach((v) => {
          this.gamelist(v);
        });
        this.allinslot.forEach((v) => {
          this.gamelist(v.toUpperCase());
        });
      } else if (val == "casino" || val == "main_casino") {
        this.gamelist("CASINO");
      } else if (val == "sport") this.gamelist("sport");
      else if (val == "showtv") {
        this.gamelist("EVOPREMIUM");
      } else if (val == "providers") {
        this.all_game["slot"].forEach((v) => {
          this.gamelist(v);
        });
        this.allinslot.forEach((v) => {
          this.gamelist(v.toUpperCase());
        });
        this.gamelist("allcasino");
      } else if (val == "hot") {
        this.gamelist("HOT");
      }
    },
    more: function () {
      this.flag_slot = 1;
    },
  },
  watch: {
    show_id(newval) {
      this.change_page(newval);
    },
  },
  computed: {
    ...mapGetters(["all_game", "list"]),
    g_type() {
      if (this.$route.params.type != null) return this.$route.params.type;
      else return "";
    },
    sub_type() {
      if (this.$route.params.sub_type != null)
        return this.$route.params.sub_type;
      else return "";
    },
    slot_list() {
      if (this.sub_type != null && this.sub_type != "")
        return [this.sub_type.toLowerCase()];
      else return this.all_game["slot"].concat(this.allinslot);
    },
    c_s_list() {
      return function (id) {
        this.list;
        if (id == "showtv") id = "evopremium";
        return JSON.parse(localStorage.getItem(id + "_all_gamelist"));
      };
    },
    pp_list() {
      return function (jj) {
        this.list;
        if (this.sub_type != null && this.sub_type != "") {
          if (jj.toUpperCase().indexOf("A1-") != -1) {
            return JSON.parse(
              localStorage.getItem(
                "a1_" + jj.split("-")[1].toLowerCase() + "_gamelist"
              )
            );
          } else
            return JSON.parse(
              localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
            );
        } else {
          try {
            if (this.list.length <= 0) {
              return [];
            } else {
              if (jj.indexOf("A1-") != -1) {
                return JSON.parse(
                  localStorage.getItem(
                    "a1_" + jj.split("-")[1].toLowerCase() + "_gamelist"
                  )
                ).slice(0, 15);
              } else
                return JSON.parse(
                  localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
                ).slice(0, 15);
            }
          } catch {
            return [];
          }
        }
      };
    },
    filterSearch() {
      if (this.flag_slot == 0) return this.all_game["slot"];
      else return this.all_game["slot"].concat(this.allinslot);
    },
  },
  mounted() {},
  created() {
    this.change_page(this.show_id);
  },
};
</script>

<template>
  <div>
    <div class="content_top position-relative">
      <div class="header-wrapper">
        <div class="header-wrapper-left">
          <div class="d-grid hwl-d">
            <h1 class="text-new455">{{ $t("text_main1") }}</h1>
            <div class="d-grid justify-content-center align-items-center">
              <div class="d-flex justify-content-center">
                <button class="reg_instantly_btn" @click="flag_btn()">
                  {{ flag_code_uid ? $t("text_main2") : $t("deposit") }}
                </button>
              </div>
              <!-- <div
                class="d-flex align-items-center justify-self-center w-100 or_div mx-auto"
              >
                <span class="or_span" style="text-transform: uppercase">{{
                  $t("Member_or")
                }}</span>
              </div>
              <div class="contents" data-content="">
                <div class="contents_div d-flex justify-content-center">
                  <div class="provider_wrapper">
                    <button
                      type="button"
                      tabindex="0"
                      class="inline-flex relative items-center gap-2 justify-center rounded-sm font-semibold whitespace-nowrap ring-offset-background transition disabled:pointer-events-none disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:scale-[0.98] bg-grey-400 text-white betterhover:hover:bg-grey-300 betterhover:hover:text-white focus-visible:outline-white text-sm leading-none shadow-md py-[0.8125rem] px-[1rem]"
                      data-analytics="provider-login-facebook"
                      data-button-root=""
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 96 96"
                        class="svg-icon"
                        style=""
                      >
                        <path
                          d="M95.94 47.97C95.94 21.467 74.473 0 47.97 0S0 21.467 0 47.97c0 22.486 15.47 41.374 36.397 46.59v-31.9h-9.894V47.97h9.894v-6.296c0-16.31 7.376-23.925 23.446-23.925 3.058 0 8.274.6 10.433 1.2v13.31c-1.14-.12-3.118-.18-5.516-.18-7.856 0-10.914 3-10.914 10.734v5.157h15.65l-2.698 14.69H53.846v32.98C77.591 92.762 96 72.555 96 48.03l-.06-.06Z"
                          fill="#0866FF"
                        ></path>
                        <path
                          d="m66.738 62.66 2.699-14.69h-15.65v-5.157c0-7.735 3.057-10.733 10.913-10.733 2.458 0 4.437 0 5.516.18V18.948c-2.158-.6-7.375-1.2-10.433-1.2-16.01 0-23.446 7.556-23.446 23.926v6.296h-9.894v14.69h9.894v31.9c3.718.9 7.615 1.44 11.573 1.44a46.6 46.6 0 0 0 5.816-.36V62.66h13.012Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div class="provider_wrapper">
                    <button
                      type="button"
                      tabindex="0"
                      class="inline-flex relative items-center gap-2 justify-center rounded-sm font-semibold whitespace-nowrap ring-offset-background transition disabled:pointer-events-none disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:scale-[0.98] bg-grey-400 text-white betterhover:hover:bg-grey-300 betterhover:hover:text-white focus-visible:outline-white text-sm leading-none shadow-md py-[0.8125rem] px-[1rem]"
                      data-analytics="provider-login-google"
                      data-button-root=""
                    >
                      <svg
                        id="layer-google-logo"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 16 16"
                        class="svelte-7etqum"
                      >
                        <clipPath id="clip-google-logo">
                          <rect
                            class="cls-google-logo-2"
                            width="16"
                            height="16"
                          ></rect>
                        </clipPath>
                        <g id="layer-google-logo-2">
                          <g class="cls-google-logo-1">
                            <path
                              class="cls-google-logo-4"
                              fill="#4285f4"
                              d="M15.68,8.18c0-.57-.05-1.11-.15-1.64h-7.53v3.09h4.31c-.19,1-.75,1.85-1.6,2.41v2.01h2.59c1.51-1.39,2.39-3.44,2.39-5.88Z"
                            ></path>
                            <path
                              class="cls-google-logo-3"
                              fill="#34a853"
                              d="M8,16c2.16,0,3.97-.72,5.29-1.94l-2.59-2.01c-.72.48-1.63.76-2.71.76-2.08,0-3.85-1.41-4.48-3.3H.85v2.07c1.32,2.61,4.02,4.41,7.15,4.41Z"
                            ></path>
                            <path
                              class="cls-google-logo-6"
                              fill="#fbbc04"
                              d="M3.52,9.52c-.16-.48-.25-.99-.25-1.52s.09-1.04.25-1.52v-2.07H.85c-.54,1.08-.85,2.3-.85,3.59s.31,2.51.85,3.59l2.67-2.07Z"
                            ></path>
                            <path
                              class="cls-google-logo-5"
                              fill="#e94235"
                              d="M8,3.18c1.17,0,2.23.4,3.06,1.2l2.29-2.29c-1.39-1.29-3.2-2.08-5.35-2.08C4.87,0,2.17,1.79.85,4.41l2.67,2.07c.63-1.89,2.39-3.3,4.48-3.3Z"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div class="provider_wrapper">
                    <button
                      type="button"
                      tabindex="0"
                      class="inline-flex relative items-center gap-2 justify-center rounded-sm font-semibold whitespace-nowrap ring-offset-background transition disabled:pointer-events-none disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:scale-[0.98] bg-grey-400 text-white betterhover:hover:bg-grey-300 betterhover:hover:text-white focus-visible:outline-white text-sm leading-none shadow-md py-[0.8125rem] px-[1rem]"
                      data-analytics="provider-login-line"
                      data-button-root=""
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 97 96"
                        class="svg-icon"
                        style=""
                      >
                        <path
                          d="M48.121 96c26.51 0 48-21.49 48-48s-21.49-48-48-48-48 21.49-48 48 21.49 48 48 48Z"
                          fill="#06C755"
                        ></path>
                        <path
                          d="M80.161 45.23c0-14.334-14.394-26.018-32.04-26.018s-32.04 11.684-32.04 26.018c0 12.888 11.383 23.608 26.8 25.656 1.024.24 2.47.662 2.831 1.566.301.783.241 2.108.12 2.89 0 0-.36 2.29-.481 2.77-.12.784-.663 3.193 2.77 1.748 3.433-1.446 18.49-10.901 25.175-18.61 4.637-5.12 6.865-10.239 6.865-16.02Z"
                          fill="#fff"
                        ></path>
                        <path
                          d="M69.501 53.48a.603.603 0 0 0 .602-.602V50.59a.603.603 0 0 0-.602-.603h-6.143V47.64h6.143a.603.603 0 0 0 .602-.603v-2.288a.603.603 0 0 0-.602-.602h-6.143v-2.35h6.143a.603.603 0 0 0 .602-.602v-2.288a.603.603 0 0 0-.602-.602h-9.034a.604.604 0 0 0-.602.602v13.972c0 .362.301.602.602.602h9.034Zm-33.365 0a.603.603 0 0 0 .602-.602V50.59a.603.603 0 0 0-.602-.603h-6.143V38.906a.603.603 0 0 0-.602-.602h-2.289a.604.604 0 0 0-.602.602v13.972c0 .362.301.602.602.602h9.034Zm5.481-15.176h-2.289a.602.602 0 0 0-.602.602v14.032c0 .333.27.603.602.603h2.289c.332 0 .602-.27.602-.602V38.905a.602.602 0 0 0-.602-.602Zm15.478 0h-2.289a.604.604 0 0 0-.602.602v8.311l-6.384-8.672v-.06h-2.289a.604.604 0 0 0-.602.602v13.972c0 .361.301.602.602.602h2.289a.603.603 0 0 0 .602-.602v-8.311l6.384 8.672.18.181h2.471a.604.604 0 0 0 .601-.602V39.026a.604.604 0 0 0-.602-.602l-.361-.12Z"
                          fill="#06C755"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div class="provider_wrapper">
                    <button
                      type="button"
                      tabindex="0"
                      class="inline-flex relative items-center gap-2 justify-center rounded-sm font-semibold whitespace-nowrap ring-offset-background transition disabled:pointer-events-none disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:scale-[0.98] bg-grey-400 text-white betterhover:hover:bg-grey-300 betterhover:hover:text-white focus-visible:outline-white text-sm leading-none shadow-md py-[0.8125rem] px-[1rem]"
                      data-analytics="provider-login-twitch"
                      data-button-root=""
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 96 96"
                        class="svg-icon"
                        style=""
                      >
                        <path
                          d="M82.378 44.593 68.69 58.281H55.002L42.987 70.297V58.28H27.565V6.875h54.813v37.718Z"
                          fill="#fff"
                        ></path>
                        <path
                          d="M24.156 0 7 17.156v61.688h20.563V96l17.156-17.156h13.688L89.25 48V0H24.156Zm58.222 44.593L68.69 58.281H55.002L42.987 70.297V58.28H27.565V6.875h54.813v37.718Z"
                          fill="#9146FF"
                        ></path>
                        <path
                          d="M72.156 18.89h-6.875v20.563h6.875V18.89Zm-18.89 0H46.39v20.563h6.875V18.89Z"
                          fill="#9146FF"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="header-wrapper-right">
          <img
            src="@/assets/img/new_img/welcome-top-zh.png"
            height="265"
            draggable="false"
          />
        </div>
      </div>
    </div>

    <div class="content_casino_sport d-grid mt-4">
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/slot/hot')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
          ><svg fill="#b1bad3" viewBox="0 0 64 64" class="svg-icon" style="">
            <path
              d="M12.265 47.726.21 14.603a3.574 3.574 0 0 1 2.108-4.553l.024-.007 19.282-7.015a3.55 3.55 0 0 1 4.553 2.082l.008.024.694 1.92L12.69 46.073a8.9 8.9 0 0 0-.418 1.598l-.008.056ZM63.79 15.511 48.002 58.93a3.529 3.529 0 0 1-4.558 2.1l.024.009-21.948-8.001a3.58 3.58 0 0 1-2.124-4.585l-.008.024 15.787-43.39a3.555 3.555 0 0 1 4.559-2.126l-.024-.008 21.948 8a3.58 3.58 0 0 1 2.124 4.585l.008-.024v-.002ZM50.457 32.685l-1.386-3.254a1.789 1.789 0 0 0-2.333-.956l.012-.004-2.666 1.174a1.787 1.787 0 0 1-2.316-.948l-.004-.012-1.146-2.667a1.764 1.764 0 0 0-2.332-.93l.012-.004-3.28 1.386a1.738 1.738 0 0 0-.929 2.33l-.004-.01 3.92 9.255a1.816 1.816 0 0 0 2.359.928l-.012.005 9.227-3.947a1.737 1.737 0 0 0 .794-2.356l.004.01h.08Z"
            ></path>
          </svg>
          <span
            class="text-new2e3 d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main3") }}</span
          >
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/new_img/welcome-casino-zh.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-new2e3">{{ $t("text_main5") }}</p>
          <p style="color: #7f8081; font-size: 14px">
            {{ $t("text_main6") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold btn_bg_5e1">
          {{ $t("text_main7") }}</a
        >
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/main_sport')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
          ><svg fill="#b1bad3" viewBox="0 0 96 96" class="svg-icon" style="">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.287 13.917c16.599 6.854 30.869 15.965 43.231 27.143l-.001.002.126.11-.125-.112C64.262 31 65.501 17.31 60.63 1.865 56.773.739 52.34.092 47.758.092c-13.046 0-24.87 5.249-33.47 13.748v.077Zm79.997 46.514a46.803 46.803 0 0 1-7.907 15.996v-.003c-2.275-3.87-4.709-7.622-7.185-11.295l-.137.08c4.463-2.823 9.63-4.63 15.307-5.11l-.078.332ZM80.986 82.734c-4.75 4.553-10.46 8.116-17.124 10.458h-.003l.006-.108a38.977 38.977 0 0 1 9.137-22.842l-.281-.41c2.838 3.924 5.478 8.005 8.265 12.902Zm0 0 .016-.014-.015.015ZM12.017 64.772a83.99 83.99 0 0 0 9.697.599h.003l-.117-.006c.832.039 1.674.06 2.518.06 12.98 0 24.848-4.766 33.883-12.589a132.455 132.455 0 0 1 9.859 11.137 47.738 47.738 0 0 0-11.975 31.216l.284-.042c-2.68.49-5.44.751-8.269.76-21.022-.012-38.88-13.566-45.416-32.75 3.102.685 6.287 1.224 9.931 1.654l-.398-.039Zm-9.533-1.614c-.226-.05-.45-.1-.675-.152l.667.129.008.023Zm65.376.815.045-.051-.045.05ZM58 52.836l-.009-.009.01.01Zm-5.59-5.706A140.354 140.354 0 0 0 9.776 20.677l-.952-.332C3.305 28.021 0 37.61 0 47.97v.038c.018 2.3.192 4.539.512 6.733l-.033-.266c3.542.97 7.889 1.823 12.325 2.386l.488.05c16.526 1.797 30.138-1.637 39.12-9.78Zm21.58 11.182a149.73 149.73 0 0 0-10.601-11.7c7.864-10.897 10.059-25.19 6.466-41.155l.267.126C85.46 13.537 95.953 29.542 96 48.007c0 .604-.048 1.247-.097 1.904-.051.688-.104 1.393-.104 2.087h-.042c-8.002.159-15.445 2.596-21.552 6.586l-.215-.272Zm-10.601-11.7-.003-.003.003.003Z"
            ></path>
          </svg>
          <span
            class="text-new2e3 d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main4") }}</span
          ></a
        >
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/new_img/welcome-sports-zh.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-new2e3">{{ $t("text_main8") }}</p>

          <p style="color: #7f8081; font-size: 14px">
            {{ $t("text_main9") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold btn_bg_5e1">{{
          $t("text_main10")
        }}</a>
      </a>
    </div>

    <div class="mt-5">
      <span
        class="text-new2e3 d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
      >
        {{ $t("text_sponser") }}
      </span>
      <div class="content_casino_sport d-grid mt-3">
        <a
          class="d-flex flex-column"
          style="gap: 1rem"
          @click="this.$router.push('/sponsor')"
        >
          <div
            class="d-flex position-relative justify-content-center align-items-center sponsors-content"
          >
            <img
              class="content_casino_sport_img h-100"
              src="@/assets/img/sponsor/1.jpg"
              height="238"
            />
            <div class="sponsors_items-content">
              <img
                class="sponsors_items-content-img"
                src="@/assets/img/sponsor/icon1.png"
              />
              <div class="sponsors_items-content-title">JUVENTUS FC</div>
              <div class="sponsors_items-content-subtitle">
                Official Regional Partner
              </div>

              <a class="content_casino_sport_btn fw-bold btn_bg_5e1 mt-3">
                More info</a
              >
            </div>
          </div>
        </a>
        <a
          class="d-flex flex-column"
          style="gap: 1rem"
          @click="this.$router.push('/sponsor')"
        >
          <div
            class="d-flex position-relative justify-content-center align-items-center sponsors-content"
          >
            <img
              class="content_casino_sport_img h-100"
              src="@/assets/img/sponsor/2.jpg"
              height="238"
            />
            <div class="sponsors_items-content">
              <img
                class="sponsors_items-content-img"
                src="@/assets/img/sponsor/icon2.png"
              />
              <div class="sponsors_items-content-title">LEICESTER FC</div>
              <div class="sponsors_items-content-subtitle">
                Official Regional Partner
              </div>

              <a class="content_casino_sport_btn fw-bold btn_bg_5e1 mt-3">
                More info</a
              >
            </div>
          </div>
        </a>
      </div>
    </div>

    <rankt1 class="mt-4" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import rankt1 from "./rank.vue";
export default {
  name: "main",
  data() {
    return {
      flag_code_uid:
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
          ? true
          : false,
    };
  },
  computed: {},
  methods: {
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
    flag_btn() {
      if (this.flag_code_uid) {
        this.open_login("isreg");
      } else {
        this.$router.push({
          path: "/ME/wallet",
          query: { id: "deposit" },
        });
      }
    },
  },
  created() {},
  watch: {},
  mounted() {},
  components: {
    rankt1,
  },
};
</script>

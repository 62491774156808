<template>
  <div class="wc-PageView wc_myactivities">
    <div
      class="wc-PageView_ContentContainer wc-ModulePageChangePageData_ContentContainer"
    >
      <div>
        <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
          <div class="nh-NavigationHeaderModule_Title">
            {{ $t("myactivity") }}
          </div>
        </div>
      </div>
      <div>
        <header class="sticky-header">
          <div class="sticky-header-inner">
            <div class="nav-wrapper">
              <ul class="nav">
                <li
                  class="nav-item"
                  :class="this.day == 7 ? 'nav-item-selected first' : ''"
                  @click="this.day = 7"
                >
                  <div>
                    <span>{{ $t("week") }}</span>
                  </div>
                </li>

                <li
                  class="nav-item"
                  :class="this.day == 30 ? 'nav-item-selected first' : ''"
                  @click="this.day = 30"
                >
                  <div>
                    <a>{{ $t("activity_30") }}</a>
                  </div>
                </li>

                <li
                  class="nav-item last"
                  :class="this.day == 365 ? 'nav-item-selected first' : ''"
                  @click="this.day = 365"
                >
                  <div>
                    <a>{{ $t("activity_365") }}</a>
                  </div>
                </li>
              </ul>
            </div>

            <div class="sub-header">
              {{ $t("activity_tips").replaceAll("aa", this.end) }}
            </div>
          </div>
        </header>
        <div class="container">
          <div class="panel-container">
            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-summary">
                  <div class="amount" style="color: var(--text_5e1)">
                    {{ $t("cur") }} {{ loweWin }}
                  </div>

                  <div class="time-period">{{ begin }}-{{ end }}</div>
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("activity_losewin") }}</h1>
                </div>

                <h2 class="panel-subtitle">
                  {{ $t("activity_losewin_tips1") }}
                </h2>
              </header>
            </section>

            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-summary">
                  <div class="amount">{{ $t("cur") }} {{ SW }}</div>

                  <div class="time-period">{{ begin }}-{{ end }}</div>
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("activity_DW") }}</h1>
                </div>
                <h2 class="panel-subtitle">{{ $t("activity_DW_tips1") }}</h2>
              </header>
              <div class="panel-content set-values-panel flexbox-panel">
                <div class="set-value-tile">
                  <p class="set-value-title">{{ $t("activity_SD") }}</p>
                  <p class="set-value-content">{{ $t("cur") }} {{ deposit }}</p>
                </div>
                <div class="set-value-tile">
                  <p class="set-value-title">{{ $t("activity_SW") }}</p>
                  <p class="set-value-content">
                    {{ $t("cur") }} {{ withdraw }}
                  </p>
                </div>
              </div>
            </section>

            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-summary">
                  <div
                    class="amount"
                    :style="[
                      this.bet > 0
                        ? 'color:#00af4c'
                        : this.bet < 0
                        ? 'color:#e7323e'
                        : '',
                    ]"
                  >
                    {{ bet }}
                  </div>

                  <div class="time-period">{{ begin }}-{{ end }}</div>
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("betAmountTitle") }}</h1>
                </div>
              </header>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";

export default {
  name: "Home",
  computed: {
    SW: function () {
      return parseFloat(this.deposit) - parseFloat(this.withdraw);
    },
  },
  data() {
    return {
      day: 7,
      begin: dateTime.getWeekStartDate(),
      end: dateTime.getWeekEndDate(),
      deposit: 0,
      withdraw: 0,
      bet: 0,
      loweWin: 0,
    };
  },
  watch: {
    day: function (e) {
      switch (e) {
        case 7:
          this.begin = dateTime.getWeekStartDate();
          this.end = dateTime.getWeekEndDate();
          break;
        case 30:
          this.begin = dateTime.getYear() + "-" + dateTime.getMonth() + "-01";
          this.end =
            dateTime.getYear() +
            "-" +
            dateTime.getMonth() +
            dateTime.getMonthEndDate();
          break;
        case 365:
          this.begin = dateTime.getYear() + "-01-01";
          this.end = dateTime.getYear() + "-12-31";
          break;
      }
      this.Deposit();
      this.Withdraw();
      this.Repoort();
    },
  },
  methods: {
    Deposit: function () {
      let param = {
        action: "record",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          methods: "1",
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var o = res.data;
        this.deposit = 0;
        if (o.length > 0) {
          o.forEach((v) => {
            if (v.code == "Y") {
              this.deposit += parseFloat(v.payment);
            }
          });
        }
      });
    },
    Withdraw: function () {
      let param = {
        action: "record",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          methods: "2",
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var o = res.data;
        this.withdraw = 0;
        if (o.length > 0) {
          o.forEach((v) => {
            if (v.code == "Y") {
              this.withdraw += parseFloat(v.payment);
            }
          });
        }
      });
    },
    Repoort: function () {
      let param = {
        action: "mybet",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          name: "all",
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.loweWin = res.totalWinLose;
        this.bet = res.totalBetAmount;
      });
    },
  },
  created() {
    this.Deposit();
    this.Withdraw();
    this.Repoort();
  },
};
</script>

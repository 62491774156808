<template>
  <div class="col-xl-12 header_mt">
    <span
      class="text-new2e3 d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
    >
      {{ $t("text_sponser") }}
    </span>

    <div class="mt-3">
      <img src="@/assets/img/sponsor/banner-1.jpg" class="w-100 mb-3" />
      <img src="@/assets/img/sponsor/banner-2.jpg" class="w-100 mb-3" />
      <img src="@/assets/img/sponsor/banner-3.png" class="w-100" />
    </div>
  </div>
</template>

<script>
export default {
  name: "sponsor",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
  components: {},
};
</script>

<template>
  <!-- 最上面的nav -->
  <div class="header-wrapper-m">
    <div
      class="content_div fixed-header gb-logged-out pb-0"
      style="
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2),
          0 4px 6px -2px rgba(0, 0, 0, 0.1);
      "
    >
      <nav
        class="navigation-menu-wrapper d-flex align-items-center position-relative mx-0 my-0"
        style="width: 100%; max-width: 1200px"
      >
        <div
          class="logo-wrapper-div"
          style="
            position: relative;
            display: grid;
            width: 100%;
            align-items: center;
            grid-template-columns: auto 1fr;
            gap: 8px;
          "
        >
          <div class="logo-wrapper-m lcfc">
            <img
              src="@/assets/logo.png"
              class="top_head_img c-pointer d-block"
              @click="this.$router.push('/')"
            />
            <!-- <img
              src="@/assets/gembet_logo_pic.png"
              class="top_head_img c-pointer d-none top_head_img_mob"
              @click="this.$router.push('/')"
            /> -->
          </div>
          <div
            class="top-nav-container d-flex align-items-center justify-content-end"
          >
            <!-- 未登入 -->
            <div
              class="top-nav-logged-out d-flex align-items-center position-relative"
              v-if="!flag_code"
            >
              <div class="login-register-header-form d-flex align-items-center">
                <button
                  class="transparent-button transparent-button-active"
                  @click="open_login('islogin')"
                >
                  {{ $t("login1") }}</button
                ><button
                  class="transparent-button transparent-button-active signup"
                  @click="open_login('isreg')"
                >
                  {{ $t("text_join") }}
                </button>
              </div>
            </div>
            <!-- 登入 -->
            <div class="top-nav-logged-in" v-else>
              <div class="header-balance-info">
                <div class="balance-in-header me-2">
                  <balanceview />
                  <div class="available-balance-details hidden">
                    <div>
                      <span>可提取余额:</span><span>{{ $t("cur") }} 0.00 </span>
                    </div>
                    <!-- <div>
                  <span>奖金余额:</span><span>{{ $t("cur") }} 0.00 </span>
                </div> -->
                    <div class="withdraw-btn-wrapper">
                      <button
                        class="transparent-button transparent-button-active user-header-withdraw-btn"
                        @click="
                          $router.push({
                            path: '/ME/wallet',
                            query: { id: 'withdraw' },
                          })
                        "
                      >
                        {{ $t("withdrawl") }}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex flex-row align-items-center justify-content-center mt-2 mt-1025-0 w-100"
                >
                  <button
                    class="transparent-button transparent-button-active user-header-deposit-btn me-2"
                    @click="
                      $router.push({
                        path: '/ME/wallet',
                        query: { id: 'deposit' },
                      })
                    "
                  >
                    {{ $t("deposit") }}
                  </button>
                  <!-- <div
                    class="user-info-container browser-disable-text-selecting"
                    @click="show_nav()"
                  >
                    <div class="user-profile-image">t</div>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- 语言 -->
            <!-- <lang class="c-pointer d-flex"></lang> -->
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
// import Lang from "./Home/language.vue";
import balanceview from "./balance.vue";

export default {
  name: "maintop2",
  data() {
    return {
      code: Cookies.get("code"),
      isMobile: this.$Utils.isMobile(),
    };
  },
  components: {
    // Lang,
    balanceview,
  },
  computed: {
    ...mapGetters(["flag_nav", "flag_code"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
    show_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
  },
  created() {},
  watch: {},
};
</script>

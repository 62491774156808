<template>
  <div class="footer">
    <div class="d-flex row mx-auto mb-0">
      <div class="col-6 col-xl-4">
        <div class="flex-column d-inline-flex">
          <div class="">
            <span class="footer_title">{{ $t("footer_games") }}</span>
          </div>
          <div class="">
            <span class="footer_subtitle">{{ $t("footer_mini") }}</span>
          </div>
          <div class="">
            <span class="footer_subtitle">{{ $t("footer_h5") }}</span>
          </div>
          <div class="">
            <span class="footer_subtitle">{{ $t("footer_app") }}</span>
          </div>
          <div class="">
            <span class="footer_subtitle">{{ $t("footer_web") }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 col-xl-4">
        <div class="flex-column d-inline-flex">
          <div class="">
            <span class="footer_title">{{ $t("footer_help") }}</span>
          </div>
          <div class="" @click="this.$router.push('/help/K')">
            <span class="footer_subtitle">{{ $t("footer_k") }}</span>
          </div>
          <div class="" @click="this.$router.push('/help/P')">
            <span class="footer_subtitle">{{ $t("footer_p") }}</span>
          </div>
          <div class="" @click="this.$router.push('/help/R')">
            <span class="footer_subtitle">{{ $t("footer_r") }}</span>
          </div>
          <div class="" @click="this.$router.push('/help/T')">
            <span class="footer_subtitle">{{ $t("footer_t") }}</span>
          </div>
          <div class="" @click="this.$router.push('/help/F')">
            <span class="footer_subtitle">{{ $t("footer_f") }}</span>
          </div>
          <div class="" @click="this.$router.push('/help/E')">
            <span class="footer_subtitle">{{ $t("footer_e") }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-4 mt-3 mt-xl-0">
        <div class="flex-column d-inline-flex">
          <div class="">
            <div class="d-flex flex-wrap" style="width: 80%">
              <img
                class="footer_right_img"
                src="@/assets/img/footer/mga.png"
                alt=""
              /><img
                class="footer_right_img"
                src="@/assets/img/footer/pagcor.png"
                alt=""
              /><img
                class="footer_right_img"
                src="@/assets/img/footer/gambling_commission.png"
                alt=""
              /><img
                class="footer_right_img"
                src="@/assets/img/footer/gaming_curacao.png"
                alt=""
              />
            </div>
          </div>
          <div class="">
            <div class="flex-row">
              <div class="">
                <span class="footer_title">{{ $t("footer_a") }}</span>
              </div>
            </div>
          </div>
          <div class="">
            <span class="footer_subtitle" style="cursor: initial">{{
              $t("footer_a_content")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footertwo",
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
};
</script>

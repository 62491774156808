<template>
  <div>
    <div class="content_img d-flex flex-wrap other_mt">
      <iframe
        :src="getnewurl(index)"
        style="width: 100%; height: 90vh"
        marginwidth="0"
        marginheight="0"
        scrolling="auto"
        frameborder="0"
        align="center"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  props: { index: Number },
  methods: {
    getnewurl: function (t) {
      return (
        process.env.VUE_APP_BASEURL +
        "/promotions_app.aspx?station=" +
        process.env.VUE_APP_STATOIN +
        "&id=" +
        t
      );
    },
  },
};
</script>

<template>
  <div class="content-m-flex logged-in">
    <div class="affiliate">
      <div class="affiliate-actions-big-holder">
        <div class="affiliate-actions-holder active">
          <div class="affiliate-actions-cards-holder">
            <h1>{{ $t("text_refer_fri") }}</h1>
            <div class="affiliate-actions-cards-holder-2">
              <div class="affiliate-code-card-holder">
                <h1>
                  {{ $t("text_share1") }}
                </h1>
                <h2>
                  {{ $t("text_share2") }}
                </h2>
                <div class="affiliate-code-card-create-code-holder">
                  <div class="affiliate-code-card-input-btn-holder">
                    <div
                      class="d-flex align-items-start align-items-lg-end justify-content-between w-100 flex-column flex-lg-row mb-3"
                    >
                      <div class="affiliate-code-card-input-holder">
                        <label
                          >{{ $t("share_invite_code") }}：{{ sharecode }}
                        </label>
                      </div>
                      <button @click="Copy('code')">{{ $t("text_share4") }}</button>
                    </div>
                    <div
                      class="d-flex align-items-start align-items-lg-end justify-content-between w-100 flex-column flex-lg-row"
                    >
                      <div class="affiliate-code-card-input-holder">
                        <label
                          >{{ $t("share_invite_url") }}：{{ shareurl }}
                        </label>
                      </div>
                      <button @click="Copy('url')">{{ $t("text_share3") }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="affiliate-table-card-holder">
                <div class="affiliate-table-card-title">
                  <h1>您的推荐历史记录</h1>
                  <div class="normal">
                    <span style="padding-top: 5px">0</span>
                  </div>
                </div>
                <div class="affiliate-table-card-table-holder">
                  <div class="affiliate-table-card-table-titles">
                    <h4>已邀请</h4>
                    <h4>注册</h4>
                  </div>
                  <div
                    class="affiliate-table-card-table-scroll show-scrollbar"
                  ></div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="affiliate-page-rewardsContainer">
        <h1 class="affiliate-page-rewardsTitle">
          {{ $t("main_share_text1") }}
        </h1>
        <div class="">
          <div
            class="col-12 d-flex justify-content-center p-5 p-xl-0"
            style="overflow-x: auto"
          >
            <div
              class="col-xl-4 col-12 p-2 border_radius_26 position-relative advert_border"
            >
              <img
                src="@/assets/img/share/gameweb_ad1.jpg"
                class="w-100 border_radius_26"
              />
              <div class="img_code_1">
                <vue-qrcode :value="shareurl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="affiliate-page-rewardsContainer">
        <h1 class="affiliate-page-rewardsTitle">{{ $t("text_share5") }}</h1>
        <h2 class="affiliate-page-rewardsSubtitle">{{ $t("text_share6") }}</h2>
        <div class="affiliate-page-rewardsCardContainer">
          <div class="affiliate-page-rewardsCard">
            <span>{{ $t("cur") }} 50.00</span><span>{{ $t("text_share7") }}</span>
          </div>
          <div class="affiliate-page-rewardsCard">
            <span>0.25%</span><span>{{ $t("text_share8") }}</span>
          </div>
        </div>
        <span class="affiliate-page-rewardsDescription"
          >{{ $t("text_share9") }}</span
        >
      </div>
      <div class="affiliate-service-big-holder">
        <img
          src="@/assets/img/main/e3.png"
          class="affiliate-service-money-img"
          alt="money-icon"
        /><img
          src="@/assets/img/main/7f.png"
          class="affiliate-service-crown-img"
          alt="crown-icon"
        /><img
          src="@/assets/img/main/8e.png"
          class="affiliate-service-big-link-img"
          alt="big-link-icon"
        />
        <div class="affiliate-service-holder">
          <h1>{{$t("text_share10")}}</h1>
          <h2>{{$t("text_share11")}}</h2>
          <div class="affiliate-service-cards-holder">
            <div class="affiliate-service-cards-holder-2">
              <div class="affiliate-service-card-holder">
                <img src="@/assets/img/main/70.png" alt="link-icon" />
                <h1>{{$t("text_share12")}}</h1>
                <h2>
                  {{$t("text_share13")}}
                </h2>
              </div>
              <div class="affiliate-service-card-holder">
                <img src="@/assets/img/main/140.png" alt="megaphone-icon" />
                <h1>{{$t("text_share14")}}</h1>
                <h2>{{$t("text_share15")}}</h2>
              </div>
              <div class="affiliate-service-card-holder">
                <img src="@/assets/img/main/a7fc2d.png" alt="gift-icon" />
                <h1>{{$t("text_share16")}}</h1>
                <h2>{{$t("text_share17")}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
// import share2 from "./share_2.vue";
import VueQrcode from "vue-qrcode";
export default {
  props: {},
  data() {
    return {
      code: Cookies.get("code"),
      shareurl: "",
      sharecode: "",
    };
  },
  methods: {
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.shareurl = res["url"];
          this.sharecode = res["ref"];
        }
      });
    },
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
  },
  mounted() {},
  created() {
    this.share();
  },
  components: {
    // share2,
    VueQrcode,
  },
};
</script>

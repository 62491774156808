<template>
  <div>
    <loginview v-if="islogin || isreg" />

    <div class="d-flex w-100 h-100 overflow-hidden">
      <maintop1 />
      <div class="d-flex w-100 h-100 overflow-hidden flex-column" id="home">
        <maintop2 ref="getbalance_home" />
        <div
          class="row mb-5 overflow-x-hidden overflow-y-auto content_page m-0"
          id="content_page"
        >
          <div class="content_div">
            <div style="width: 100%; max-width: 1200px">
              <mainsportview v-if="Id == 'main_sport'" />
              <mainview v-if="Id == ''" />
              <game_page v-if="Id == 'slot' || Id == 'casino'" />
              <promotion v-if="Id == 'promo'" />
              <vipintro v-if="Id == 'VIP_intro'" />
              <share v-if="Id == 'share'" />
              <home2 v-if="Id == 'ME'" />
              <home3 v-if="Id == 'BO'" />
              <homehelp v-if="Id == 'help'" />
              <member v-if="Id == 'VIP'" />
              <sponsor v-if="Id == 'sponsor'" />
            </div>
          </div>
          <footertwo />
        </div>
        <prewindow></prewindow>
      </div>
    </div>

    <Footer />
    <newmessage ref="newmess"></newmessage>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import mainview from "./main.vue";
import Footer from "./Home/Footer.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import loginview from "./Login.vue";
import mainsportview from "./main_sport.vue";
import game_page from "./game_page.vue";
import promotion from "./Promotion.vue";
import vipintro from "./vip_introduce.vue";
import share from "./share.vue";
import home2 from "./Home2.vue";
import home3 from "./Home3.vue";
import homehelp from "./footer_help.vue";
import member from "./Member.vue";
import newmessage from "./new_Message.vue";
import sponsor from "./sponsor.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    maintop1,
    maintop2,
    mainview,
    Footer,
    footertwo,
    Prewindow,
    loginview,
    mainsportview,
    game_page,
    promotion,
    vipintro,
    share,
    home2,
    home3,
    homehelp,
    member,
    newmessage,
    sponsor,
  },
  computed: {
    ...mapGetters(["watch_newWindowurl", "islogin", "isreg"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {
    ...mapActions(["getBalance"]),
  },
  created() {},
  watch: {
    watch_newWindowurl: function (newval) {
      if (newval == "close") {
        this.getBalance();
        this.$refs.newmess.msg("30");
      }
    },
    islogin() {
      if (this.islogin) {
        this.$store.dispatch("logout_public");
      }
    },
    isreg() {
      if (this.isreg) {
        this.$store.dispatch("logout_public");
      }
    },
  },
  mounted() {},
};
</script>

<template>
  <div>
    <div class="top_arrow">
      <div class="left_right">
        <button class="btn_arrow" @click="turn('left')">
          <span role="img" aria-label="left"
            ><svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="left"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
              ></path>
            </svg>
          </span></button
        ><button class="btn_arrow" @click="turn('right')">
          <span role="img" aria-label="right"
            ><svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="right"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
              ></path>
            </svg>
          </span>
        </button>
      </div>
    </div>

    <swiper
      :loop="swiper_options.loop"
      :speed="swiper_options.speed"
      :slidesPerView="swiper_options.slidesPerView"
      :spaceBetween="swiper_options.spaceBetween"
      :breakpoints="swiper_options.breakpoints"
      :navigation="swiper_options.navigation"
    >
      <swiper-slide>
        <div>
          <div class="swiper_div">
            <p class="rank2_title">Rank CRASH</p>
            <div class="timer_div">
              <span class="timer_fin">{{ $t("rank_t1_text1") }}： </span>
              <div class="timer_con">
                <div class="timer_con_div">
                  <span>{{ set_time.days }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text2") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.hours }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text3") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.minutes }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text4") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.seconds }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text5") }}</span>
                </div>
              </div>
            </div>
            <div class="prize_div">
              <span>{{ $t("rank_t1_text6") }}</span
              ><span class="prize_num">&nbsp;3000 {{ $t("cur") }}</span>
            </div>
            <div class="rank2_bg">
              <div>
                <span
                  style="
                    color: transparent;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                  "
                  ><img
                    width="100%"
                    height="100%"
                    src="@/assets/img/rank/gameweb_rankcrash.png"
                /></span>
              </div>
            </div>
          </div>
          <div style="margin-top: -10px">
            <div class="rank2_con">
              <div class="rank_con_title">
                <span>{{ $t("rank_t1_text7") }}</span
                ><span>{{ $t("rank_t1_text8") }}</span
                ><span>{{ $t("rank_t1_text9") }}</span>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/B5A7DF7A5F522356CDC7BF6A77C686AB.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/3FAC106C0CA260773D90458A289E3C7C.png"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User7385677600</span
                    ><br /><span class="rank_con_prize"
                      ><span>229,597 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>450 {{ $t("cur") }}</span></span>
                </div>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/679CCB91AFDE7F9E3903E1BDC1C20141.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/A42E95B027FEFC7AA8CFC9367B167156"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User3740449567</span
                    ><br /><span class="rank_con_prize"
                      ><span>101,087 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>300 {{ $t("cur") }}</span></span>
                </div>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/E7584A60DB4BB7CE24B3F72E72F3C249.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/B1AB22E6AA23D3ACD47BAD6C3EFD2BB9"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User2473848633</span
                    ><br /><span class="rank_con_prize"
                      ><span>22,003 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>150 {{ $t("cur") }}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <div class="swiper_div">
            <p class="rank2_title">Rank MINES</p>
            <div class="timer_div">
              <span class="timer_fin"
                >{{ $t("rank_t1_text1") }}：
                <!-- <div class="battle_img">
                  <img src="@/assets/img/rank/battle.png" /></div> -->
              </span>
              <div class="timer_con">
                <div class="timer_con_div">
                  <span>{{ set_time.days }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text2") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.hours }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text3") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.minutes }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text4") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.seconds }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text5") }}</span>
                </div>
              </div>
            </div>
            <div class="prize_div">
              <span>{{ $t("rank_t1_text6") }}</span
              ><span class="prize_num">&nbsp;1000 {{ $t("cur") }}</span>
            </div>
            <div class="rank2_bg">
              <div>
                <span
                  style="
                    color: transparent;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                  "
                  ><img
                    width="100%"
                    height="100%"
                    src="@/assets/img/rank/gameweb_rankmines.png"
                /></span>
              </div>
            </div>
          </div>
          <div style="margin-top: -10px">
            <div class="rank2_con">
              <div class="rank_con_title">
                <span>{{ $t("rank_t1_text7") }}</span
                ><span>{{ $t("rank_t1_text8") }}</span
                ><span>{{ $t("rank_t1_text9") }}</span>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/B5A7DF7A5F522356CDC7BF6A77C686AB.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/B1AB22E6AA23D3ACD47BAD6C3EFD2BB9.png"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User1468821695</span
                    ><br /><span class="rank_con_prize"
                      ><span>320,689 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>1,500 {{ $t("cur") }}</span></span>
                </div>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/679CCB91AFDE7F9E3903E1BDC1C20141.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/3FAC106C0CA260773D90458A289E3C7C"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User1216801047</span
                    ><br /><span class="rank_con_prize"
                      ><span>69,658 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>1,000 {{ $t("cur") }}</span></span>
                </div>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/E7584A60DB4BB7CE24B3F72E72F3C249.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/A42E95B027FEFC7AA8CFC9367B167156.png"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User7850379046</span
                    ><br /><span class="rank_con_prize"
                      ><span>65,789 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>500 {{ $t("cur") }}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <div class="swiper_div">
            <p class="rank2_title">Rank TOWER</p>
            <div class="timer_div">
              <span class="timer_fin"
                >{{ $t("rank_t1_text1") }}：
                <!-- <div class="battle_img">
                  <img src="@/assets/img/rank/battle.png" /></div> -->
              </span>
              <div class="timer_con">
                <div class="timer_con_div">
                  <span>{{ set_time.days }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text2") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.hours }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text3") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.minutes }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text4") }}</span>
                </div>
                <div class="timer_con_dot">:</div>
                <div class="timer_con_div">
                  <span>{{ set_time.seconds }}</span
                  ><span class="timer_con_text">{{ $t("rank_t1_text5") }}</span>
                </div>
              </div>
            </div>
            <div class="prize_div">
              <span>{{ $t("rank_t1_text6") }}</span
              ><span class="prize_num">&nbsp;2000 {{ $t("cur") }}</span>
            </div>
            <div class="rank2_bg">
              <div>
                <span
                  style="
                    color: transparent;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                  "
                  ><img
                    width="100%"
                    height="100%"
                    src="@/assets/img/rank/gameweb_ranktower.png"
                /></span>
              </div>
            </div>
          </div>
          <div style="margin-top: -10px">
            <div class="rank2_con">
              <div class="rank_con_title">
                <span>{{ $t("rank_t1_text7") }}</span
                ><span>{{ $t("rank_t1_text8") }}</span
                ><span>{{ $t("rank_t1_text9") }}</span>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/B5A7DF7A5F522356CDC7BF6A77C686AB.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/3FAC106C0CA260773D90458A289E3C7C.png"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User4964758796</span
                    ><br /><span class="rank_con_prize"
                      ><span>46,076 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>300 {{ $t("cur") }}</span></span>
                </div>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/679CCB91AFDE7F9E3903E1BDC1C20141.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/DCA6E90D81B3D0DB9CDCA502FD1F3F7E"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User9016182213</span
                    ><br /><span class="rank_con_prize"
                      ><span>20,587 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>200 {{ $t("cur") }}</span></span>
                </div>
              </div>
              <div class="rank_con_con">
                <div class="rank_con_img">
                  <img
                    src="@/assets/img/rank/E7584A60DB4BB7CE24B3F72E72F3C249.png"
                    alt=""
                  />
                </div>
                <div class="rank_con_center">
                  <!-- <img
                            class="rank_con_big_head"
                            src="@/assets/img/rank/052CF049EB71228A9A7EA5522BA73277"
                            alt=""
                          /> -->
                  <div>
                    <span class="text-new2e3 fw-bolder">User3198291372</span
                    ><br /><span class="rank_con_prize"
                      ><span>16,917 {{ $t("cur") }}</span></span
                    >
                  </div>
                </div>
                <div class="rank_con_div_prize">
                  <span class="rank_con_prize"><span>100 {{ $t("cur") }}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { reactive } from "vue";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);
import "@/assets/style/rankt1.css";
export default {
  props: {},
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      loop: true,
      speed: 1000,
      slidesPerView: 3,
      spaceBetween: 15,
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 3,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      endTime1: new Date("2024-07-28T18:37:59"),
      set_time: {},
    };
  },
  methods: {
    turn(e) {
      if (e == "left") {
        document.querySelector(".swiper-button-prev").click();
      } else {
        document.querySelector(".swiper-button-next").click();
      }
    },
    remainingTime() {
      let now = new Date();
      let diff = this.endTime1 - now;
      if (diff > 0) {
        let seconds = Math.floor(diff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        this.set_time = {
          seconds: seconds % 60,
          minutes: minutes % 60,
          hours: hours % 24,
          days: days,
        };
      } else {
        this.set_time = {
          seconds: 0,
          minutes: 0,
          hours: 0,
          days: 0,
        };
        clearInterval(this.timer);
      }
    },
  },
  computed: {},
  mounted() {
    this.timer = setInterval(() => {
      this.remainingTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {},
};
</script>

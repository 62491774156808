<template>
  <div class="col-xl-12 header_mt">
    <div id="root">
      <div id="cricket-page-wrapper" class="sport-page-wrapper">
        <!-- <div id="loading">
          <img
            src="@/assets/img/main/ben-redblock-loading.gif"
            style="max-width: 75%"
          />
        </div> -->
        <div class="sport-widgets">
          <div
            id="sport-root"
            class="h-100"
            data-ck-props='{"theme":{"pbgc":"FFF","sbgc":"FFF","pc":"1475e1","sc":"e5e9f7","tc":"000","tpc":"000","tsc":"000","qlbc":"ccc","primaryColorLinearGradientParams":{"deg":"","color1":"1475e1","opacity1":"","color2":"1475e1","opacity2":"","color3":"","opacity3":""},"quickLink":{"sponsor":true},"burger":false,"eventList":{"statementLink":true}},"displayHeader":false,"displayPCLeftSidebar":true,"displayPCNavbar":false,"displayPCNavHome":false,"displayPCNavSearch":false,"loginCart":true}'
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import "@/assets/style/sport.scss";

export default {
  name: "main_sport",
  data() {
    return {
      code: Cookies.get("code"),
      TOKEN:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiODg1YWI5NDQtNzhmMS00NmZkLTgzNTItNDc1MTJlM2VhOTY3IiwicGxheWVyX2lkIjoiaW5kaWFfMy4wX2Zvcl9kaXNwbGF5IiwibWVyY2hhbnRfY29kZSI6ImJhY2tvZmZpY2UtZDllMzIiLCJpc3N1ZWRfYXQiOiIyMDIyLTEwLTI4VDA4OjA5OjUzLjU0ODE0ODcyNloiLCJleHBpcmVzX2F0IjoiMjEyMi0xMC0yOFQwMDowMDowMC4wMDAwMDE2ODdaIn0.WEL6t_UEf8KglL-p_OLQe5xsHhDgaDelQMYZniCkcGk",
    };
  },
  created() {
    var scripts = document.getElementsByTagName("script");
    if (scripts.length) {
      for (var i = 0; i < scripts.length; i++) {
        if (
          scripts[i].src.includes("http") &&
          !scripts[i].src.includes(location.host)
        ) {
          window.location.reload();
        }
      }
    }
    if (this.code != null) {
      let param = {
        action: "stage_balance",
        body: {
          uid: localStorage.getItem("uid"),
          ip: localStorage.getItem("ip"),
          session_code: Cookies.get("code"),
          device: "web",
          gametype: "SPORT",
          gamekind: "sport",
          gameid: "",
          mode: "sport",
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        let script = document.createElement("script");
        script.src = "https://cdn.seondf.com/js/v5/agent.js";
        document.body.appendChild(script);
        script = document.createElement("script");
        script.innerHTML =
          '(function(n,t,i,r,u,f,e,o,s){n[u]||(s=n[u]=function(){(n[u].q=n[u].q||[]).push(arguments)},e=t.createElement(i),o=t.getElementsByTagName(i)[0],e.async=1,e.src=r,o.parentNode.insertBefore(e,o))})(window,document,"script",`https://sprodm.uni247.xyz/international/international-pro.js?v="${Date.now()}"`,"CRIC");CRIC({token:"' +
          res +
          '"});';
        document.body.appendChild(script);
      });
    } else {
      let script = document.createElement("script");
      script.src = "https://cdn.seondf.com/js/v5/agent.js";
      document.body.appendChild(script);
      script = document.createElement("script");
      script.innerHTML =
        '(function(n,t,i,r,u,f,e,o,s){n[u]||(s=n[u]=function(){(n[u].q=n[u].q||[]).push(arguments)},e=t.createElement(i),o=t.getElementsByTagName(i)[0],e.async=1,e.src=r,o.parentNode.insertBefore(e,o))})(window,document,"script",`https://sprodm.uni247.xyz/international/international-pro.js?v="${Date.now()}"`,"CRIC");CRIC({token:"' +
        this.TOKEN +
        '"});';
      document.body.appendChild(script);
    }
  },
  components: {
  },
  computed: {
  },
  watch: {},
};
</script>

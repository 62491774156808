<template>
  <div v-if="pop.isLoading"></div>
</template>
<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
export default {
  data() {
    return { balance: "0.00" };
  },
  computed: {
    ...mapGetters(["pop"]),
  },
  methods: {
    comfirm_fun: function (e) {
      switch (e) {
        case "home":
          this.$router.push("/");
          break;
        case "Logout":
        case "relogin":
          Cookies.remove("code");
          localStorage.removeItem("uid");
          localStorage.removeItem("utype");
          // this.$store.commit("islogin", true);
          this.$router.push("/");
          window.location.reload();
          break;
        case "allgameTolobby":
        case "withdrawl2":
        case "message":
        case "regi_success":
        case "wallet":
          window.location.reload();
          break;
      }

      this.$store.commit("Pop", { isLoading: false });
    },
    show_alert: function () {
      this.$swal({
        html: this.pop.data.msg,
        icon: this.pop.data.action,
        showCancelButton: false,
        confirmButtonText: this.$t("shared_comfirm"),
        cancelButtonText: this.$t("shared_cancel"),
      }).then(() => {
        this.comfirm_fun(this.pop.data.type);
        // if (result.isConfirmed === true) {
        //   this.comfirm_fun(this.pop.data.type);
        // }
        // if (result.dismiss === "cancel") {
        //   console.log("cancel");
        // }
      });
    },
  },
  watch: {
    pop: function () {
      if (this.pop.isLoading) this.show_alert();
    },
  },
  created() {},
};
</script>
